import { template as template_7eb37e4149924505a6423a27dbf74096 } from "@ember/template-compiler";
const FKLabel = template_7eb37e4149924505a6423a27dbf74096(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
