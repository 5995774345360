import { template as template_cc5e87ff8388449a8533cb0922b1d3be } from "@ember/template-compiler";
const SidebarSectionMessage = template_cc5e87ff8388449a8533cb0922b1d3be(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
