import { template as template_f574c9b7766b41d8b4be5960f33fdf5e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_f574c9b7766b41d8b4be5960f33fdf5e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
