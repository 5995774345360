import { template as template_0e5b224705f84b56b6d0596723432c61 } from "@ember/template-compiler";
const WelcomeHeader = template_0e5b224705f84b56b6d0596723432c61(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
