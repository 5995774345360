import { template as template_72be95951b374047b060efd9c671dfd7 } from "@ember/template-compiler";
const FKText = template_72be95951b374047b060efd9c671dfd7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
