import { template as template_81d31bc7261b4a129b275c56c0c8d02e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_81d31bc7261b4a129b275c56c0c8d02e(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
