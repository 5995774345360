import { template as template_c442aeeb5fad4e78a7973e203542606c } from "@ember/template-compiler";
const FKControlMenuContainer = template_c442aeeb5fad4e78a7973e203542606c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
